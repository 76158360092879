import React from "react";

const Banner = () => {
  return (
    <section className="bg-red_500 px-5 md:px-12 h-[70px] w-full mb-12 mt-5  overflow-hidden flex items-center justify-between whitespace-nowrap">
      <div className=" overflow-hidden flex items-center justify-between whitespace-nowrap">
        <div className="red-banner flex m-0">
          <p className="text-sm f-helvetica-bold text-white w-[800px]">
            NEW MEMBER SIGN UP BONUS 25% DEPOSIT MATCH
          </p>
          <p className="text-sm f-helvetica-bold text-white w-[800px]">
            NEW MEMBER SIGN UP BONUS 25% DEPOSIT MATCH
          </p>
          <p className="text-sm f-helvetica-bold text-white w-[800px]">
            NEW MEMBER SIGN UP BONUS 25% DEPOSIT MATCH
          </p>
        </div>
        <div className="red-banner flex m-0">
          <p className="text-sm f-helvetica-bold text-white w-[800px]">
            NEW MEMBER SIGN UP BONUS 25% DEPOSIT MATCH
          </p>
          <p className="text-sm f-helvetica-bold text-white w-[800px]">
            NEW MEMBER SIGN UP BONUS 25% DEPOSIT MATCH
          </p>
          <p className="text-sm f-helvetica-bold text-white w-[800px]">
            NEW MEMBER SIGN UP BONUS 25% DEPOSIT MATCH
          </p>
        </div>
      </div>
    </section>
  );
};

export default Banner;
