import React from "react";
import MissionIcon from "../assets/red-chip.png";

const Mission = () => {
  return (
    <section className="relative block px-5 pb-16 overflow-hidden pt-14 mission-border">
      <div>
        <div className="mb-[80px]">
          <h2 className="text-white text-[28px] f-helvetica-black uppercase text-center">
            WHY PLAY FREE SOCIAL POKER ONLINE WITH HULSRS POKER?
          </h2>
        </div>

        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4 place-items-start">
          {/* List 01 :  BECOME A HUSLRS POKER CHAMPION */}
          <div className="flex flex-col items-center justify-center gap-6 text-center xl:px-8">
            <img
              src={MissionIcon}
              alt="mission icon"
              loading="lazy"
              className="object-contain size-24 md:size-28"
            />
            <div>
              <h3 className="text-xl text-white uppercase md:text-2xl f-helvetica-black">
                BECOME A HUSLRS <br /> POKER CHAMPION
              </h3>
              <p className="mt-4 text-base text-white md:text-xl f-helvetica-light">
                Do you have what it takes to be the best poker player online? The HUSLRS Poker Championships are calling. Test your skills in one—or all four—of our flagship Championships for your chance to win merch, trophies, glory and more.
              </p>
            </div>
          </div>

          {/* List 02 : PLAY FREE POKER ONLINE */}
          <div className="flex flex-col items-center justify-center gap-6 text-center xl:px-8">
            <img
              src={MissionIcon}
              alt="mission icon"
              loading="lazy"
              className="object-contain size-24 md:size-28"
            />
            <div>
              <h3 className="text-xl text-white uppercase md:text-2xl f-helvetica-black">
                PLAY FREE  <br /> POKER ONLINE
              </h3>
              <p className="mt-4 text-base text-white md:text-xl f-helvetica-light">
                Feel the thrill and excitement of an online multi-table poker tournament. We’ve got every poker format covered, and you can play them all for free! Check out our wide range of free poker tournaments online. There’s something for every poker player!
              </p>
            </div>
          </div>

          {/* List 03 : WEEKLY OFFERS & PROMOTIONS */}
          <div className="flex flex-col items-center justify-center gap-6 text-center xl:px-8">
            <img
              src={MissionIcon}
              alt="mission icon"
              loading="lazy"
              className="object-contain size-24 md:size-28"
            />
            <div>
              <h3 className="text-xl text-white uppercase md:text-2xl f-helvetica-black">
                WEEKLY OFFERS &  <br /> PROMOTIONS
              </h3>
              <p className="mt-4 text-base text-white md:text-xl f-helvetica-light">
                The action never stops at HUSLRS Poker. Social poker players will love our fierce collection of weekly poker tournaments and always on promotions. Every week there’s something new. We’ll even throw in a few prizes to make it worth your while.
              </p>
            </div>
          </div>

          {/* List 04 :  HUGE EVENTS EVERY WEEK! */}
          <div className="flex flex-col items-center justify-center gap-6 text-center xl:px-8">
            <img
              src={MissionIcon}
              alt="mission icon"
              loading="lazy"
              className="object-contain size-24 md:size-28"
            />
            <div>
              <h3 className="text-xl text-white uppercase md:text-2xl f-helvetica-black">
                HUGE EVENTS  <br /> EVERY WEEK!
              </h3>
              <p className="mt-4 text-base text-white md:text-xl f-helvetica-light">
                HUSLRS Poker is the home of social poker, with online poker tournaments and special events that players can always take part in for free. Test your skills and play online poker against the best of the best.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Mission;
